<template>
  <div>
    <v-row dense>
      <v-col cols="4">
        <select-pos-model
          v-model="id_pos"
          :label="$t('labels.pos')"
          dense
          outlined
          clearable
          hide-details
          @change="onPosUpdate"
        ></select-pos-model>
      </v-col>
      <v-col cols="8">
        <input-qr-scan-model
          v-model="code"
          ref="codeRef"
          :label="$t('labels.uid')"
          @keyupEnter="inputScan"
          :auto-close="false"
        ></input-qr-scan-model>
      </v-col>
    </v-row>

    <div class="mt-3" v-if="item.id">
      <v-alert
        :color="item.basket_code || item.cell_id ? 'success' : 'error'"
        text
        dark
        class="text-center"
      >
        <template v-if="item.basket_code || item.cell_id">
          <span class="fs-16"
            >{{
              item.basket_code ? $t("labels.basket") : $t("labels.position")
            }}:
          </span>
          <span class="text-h5">{{ item.basket_code || item.cell_id }}</span>
        </template>
        <template v-else>
          <span class="fs-16">{{ $t("labels.no_position") }}</span>
        </template>
      </v-alert>

      <v-row dense class="fs-14">
        <v-col cols="4">{{ $t("labels.uid") }}</v-col>
        <v-col cols="8">{{ item.sku_uid }} </v-col>
        <v-col cols="4">{{ $t("labels.name") }}</v-col>
        <v-col cols="8">{{ item.name }}</v-col>
        <v-col cols="4">{{ $t("labels.size") }}</v-col>
        <v-col cols="8">{{ item.size }}</v-col>
        <v-col cols="4">{{ $t("labels.goods_description") }}</v-col>
        <v-col cols="8">{{ item.description }}</v-col>
        <v-col cols="12">
          <ImageViewer v-if="item.url_images" :url="item.url_images" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { GOODS_UID_ACTION_TYPE_FIND_LOCATION } from "@/libs/const";

export default {
  name: "UidLocation",
  components: {
    ImageViewer: () => import("@/components/common/ImageViewer"),
  },
  data: () => ({
    item: {},
    id_pos: null,
    code: null,
  }),
  methods: {
    onPosUpdate() {
      if (!this.id_pos) {
        return false;
      }
      this.$nextTick(() => {
        this.$refs.codeRef.focusInput();
      });
    },
    async inputScan() {
      if (!this.code) {
        return false;
      }

      try {
        const { data } = await httpClient.post("/get-item", {
          code: this.code,
          id_pos: this.id_pos,
          action_type: GOODS_UID_ACTION_TYPE_FIND_LOCATION,
        });
        this.item = { ...data };
        this.code = null;
        this.$root.$emit("playSuccessAudio");
      } catch (e) {
        this.item = {};
        this.code = null;
        this.$root.$emit("playErrorAudio");
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
